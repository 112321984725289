import _ from 'lodash';
function convertPropertiesToArray(properties) {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return _.map(properties, (type, key) => ({
        type,
        key,
        isNew: false,
    }));
}
export default convertPropertiesToArray;
