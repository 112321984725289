import { ColumnTypes } from '@onereach/types-contacts-api';
import { arrayItemsTypes } from '@/constants';
import getDefaultIdentifier from './getDefaultIdentifier';
function getEmptyContactFormValuesV2(fieldSchemas) {
    return fieldSchemas.map(fieldSchema => ({
        schemaId: fieldSchema.id,
        value: arrayItemsTypes.indexOf(fieldSchema.type) != -1 ? [] :
            fieldSchema.type === ColumnTypes['identifier object'] ? getDefaultIdentifier() :
                undefined,
    }));
}
export default getEmptyContactFormValuesV2;
