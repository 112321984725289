import _ from 'lodash';
import { defineStore } from 'pinia';
import { ContactsApiV2 } from '@/api';
import { TranslatableError } from '@/errors';
import { getStoreIdWithoutVersion } from '@/utils';
export const useSchemaPresets = defineStore('schemaPresets', {
    state: () => ({
        total: null,
        items: [],
    }),
    getters: {
        getSchemaPresetById: (state) => {
            return (id) => {
                return _.find(state.items, { id });
            };
        },
    },
    actions: {
        async getSchemaPresets() {
            const resp = await ContactsApiV2.schemaPresetApi.listSchemaPresets();
            this.total = resp.total;
            this.items = _.orderBy(resp.items, 'label');
        },
        async deleteSchemaPreset(id) {
            await ContactsApiV2.schemaPresetApi.deleteSchemaPreset(id, { softDelete: false });
            await this.getSchemaPresets();
        },
        _updateSchemaPreset(data) {
            const index = _.findIndex(this.items, { id: data.id });
            if (index > -1) {
                this.items[index] = data;
            }
            else {
                this.items.push(data);
            }
        },
        async createSchemaPreset(dto) {
            try {
                const data = await ContactsApiV2.schemaPresetApi.createSchemaPreset(dto);
                await this.getSchemaPresets();
                return data;
            }
            catch (e) {
                if (e instanceof Error && e.message.includes('already exist')) {
                    throw new TranslatableError(`errors.${getStoreIdWithoutVersion(this.$id)}.schemaPresetWithThisNameAlreadyExists`, e);
                }
                throw e;
            }
        },
        async getSchemaPreset(id) {
            const data = await ContactsApiV2.schemaPresetApi.getSchemaPreset(id);
            this._updateSchemaPreset(data);
        },
        async updateSchemaPreset(id, dto) {
            const data = await ContactsApiV2.schemaPresetApi.updateSchemaPreset(id, dto);
            this._updateSchemaPreset(data);
        },
    },
});
export default useSchemaPresets;
