import { createPinia as createPiniaOrigin } from 'pinia';
import { PiniaLogger } from 'pinia-logger';
import { LoadingHandlerPiniaPlugin, SentryMonitoringPlugin, TranslatableErrorHandlerPiniaPlugin } from './plugins';
export { default as useSettings } from './settings';
export { default as useAuth } from './auth';
export { default as useContactsV1 } from './contacts/contactsV1';
export { default as useContactsV2 } from './contacts/contactsV2';
export { default as useContactBooksV1 } from './contactsBooks/contactsBooksV1';
export { default as useContactBooksV2 } from './contactsBooks/contactsBooksV2';
export { default as useAccessSettings } from './accessSettings';
export { default as useContactRuleGroups } from './contactsRuleGroups';
export { default as useSchemaPresets } from './schemaPresets';
export { default as useFieldSchemas } from './fieldSchemas';
export { default as useFiltersV1 } from './filters/filtersV1';
export { default as useFiltersV2 } from './filters/filtersV2';
export { default as useMigrations } from './migrations';
function createPinia() {
    return createPiniaOrigin()
        .use(PiniaLogger({
        expanded: false,
        disabled: process.env.NODE_ENV === 'production',
    }))
        .use(LoadingHandlerPiniaPlugin())
        .use(SentryMonitoringPlugin({
        disabled: process.env.NODE_ENV === 'development',
        ignore: ['auth'],
    }))
        .use(TranslatableErrorHandlerPiniaPlugin);
}
export default createPinia;
