import { OrCheckbox, OrSelect } from '@onereach/ui-components-legacy';
import useVuelidate from '@vuelidate/core';
import { requiredUnless } from '@vuelidate/validators';
import { useVModel } from '@vueuse/core';
import _ from 'lodash';
import { defineComponent, toRefs, computed } from 'vue';
import { getExtendedFieldsSchemas } from '@/utils/getExtendedFieldsSchemas';
export default defineComponent({
    name: 'ContactBooksMigrationFormItem',
    components: {
        OrCheckbox,
        OrSelect,
    },
    props: {
        modelValue: {
            type: [String, null],
            required: false,
            default: undefined,
        },
        columnKey: {
            type: String,
            required: false,
            default: '',
        },
        label: {
            type: String,
            required: false,
            default: '',
        },
        typeConstraint: {
            type: String,
            required: false,
            default: undefined,
        },
        allFields: {
            type: Array,
            required: false,
            default: () => [],
        },
        unavailableFieldIds: {
            type: Array,
            required: false,
            default: () => [],
        },
        isLoading: {
            type: Boolean,
            required: false,
            default: false,
        },
        formItems: {
            type: Set,
            required: false,
            default: () => new Set(),
        },
        validationScope: {
            type: String,
            required: false,
            default: 'ContactBooksMigration',
        },
    },
    emits: ['update:modelValue'],
    setup(props, { emit }) {
        const localValue = useVModel(props, 'modelValue', emit);
        const { validationScope } = toRefs(props);
        const v$ = useVuelidate({ $scope: validationScope.value });
        const extendedFields = computed(() => getExtendedFieldsSchemas(props.allFields));
        return {
            extendedFields,
            localValue,
            v$,
        };
    },
    computed: {
        availableFields() {
            return _.reject(this.extendedFields, field => (field.id !== this.localValue &&
                _.includes(this.unavailableFieldIds, field.id)) ||
                (this.typeConstraint ? field.type !== this.typeConstraint : false));
        },
        fieldsOptions() {
            return _.map(this.availableFields, field => ({
                value: field.id,
                label: field.label,
            }));
        },
        isUnselected() {
            return this.localValue === null;
        },
    },
    validations() {
        return {
            localValue: {
                requiredIfSelected: requiredUnless(this.isUnselected),
            },
        };
    },
    mounted() {
        this.formItems.add(this);
    },
    beforeUnmount() {
        this.formItems.delete(this);
    },
    methods: {
        searchFieldSchemasOptions({ search }) {
            return this.availableFields
                .filter(fieldSchema => fieldSchema.label.toLowerCase().includes(search.toLowerCase()) || fieldSchema.id === search).map(fieldSchema => ({
                value: fieldSchema.id,
                label: fieldSchema.label,
            }));
        },
        handleSelect() {
            if (_.isNull(this.localValue)) {
                this.localValue = undefined;
            }
            else {
                this.localValue = null;
            }
        },
    },
});
