import { MigrationState } from '@onereach/types-contacts-api';
import { defineStore } from 'pinia';
import { ContactsApiV2 } from '@/api';
import ContactsApi from '@/api/ContactsApiV2';
import { MigrationsStatus } from '@/constants';
import { useSettings } from '@/stores';
import { debouncePromise } from '@/utils/debouncePromise';
const MAX_RETRIES = 3;
const RETRY_DELAY_MS = 3000;
const useMigrations = defineStore('migrations', {
    state: () => ({
        migrations: [],
        migrationsLoading: false,
        migrationsStatus: MigrationsStatus.UNDEFINED,
    }),
    actions: {
        async getMigrations() {
            this.migrationsLoading = true;
            this.migrations = await ContactsApi.migrationsApi.listMigrations();
            this.migrationsLoading = false;
        },
        async launchMigration() {
            const settings = useSettings();
            const migrate = async (retries = 0) => {
                try {
                    await this.migrateContactsTables();
                }
                catch (e) {
                    if (retries > MAX_RETRIES) {
                        throw e;
                    }
                    await debouncePromise(() => migrate(retries + 1), RETRY_DELAY_MS);
                }
            };
            this.migrationsStatus = MigrationsStatus.IN_PENDING;
            const migrationPromise = migrate();
            settings.systemBusy = true;
            await migrationPromise;
            await Promise.all([
                settings.getOperationInfo(),
                this.getMigrations(),
            ]);
        },
        async getMigrationStatus() {
            const migrationState = await ContactsApiV2.migrationsApi.getMigrationState();
            switch (migrationState) {
                case MigrationState.ToDo:
                    this.migrationsStatus = MigrationsStatus.TO_DO;
                    break;
                case MigrationState.Done:
                    this.migrationsStatus = MigrationsStatus.RESOLVED;
                    break;
                case MigrationState.InProgress:
                    this.migrationsStatus = MigrationsStatus.IN_PENDING;
                    break;
                case MigrationState.NotStarted:
                    this.migrationsStatus = MigrationsStatus.NOT_STARTED;
                    break;
                default:
                    return;
            }
        },
        async migrateContactsTables() {
            const migrationPollResult = await ContactsApiV2.migrationsApi.pollMigrationUntilDone();
            if (!migrationPollResult) {
                this.migrationsStatus = MigrationsStatus.FAILED;
            }
            else {
                this.migrationsStatus = MigrationsStatus.RESOLVED;
            }
        },
        async dropLockTable() {
            await ContactsApiV2.migrationsApi.dropMigrationLockTable();
            await this.getMigrationStatus();
        },
    },
});
export default useMigrations;
