import { ColumnTypes } from '@onereach/types-contacts-api';
import _ from 'lodash';
var FieldGrouping;
(function (FieldGrouping) {
    FieldGrouping[FieldGrouping["Id"] = 0] = "Id";
    FieldGrouping[FieldGrouping["Simple"] = 1] = "Simple";
    FieldGrouping[FieldGrouping["ArrayOfStrings"] = 2] = "ArrayOfStrings";
    FieldGrouping[FieldGrouping["Identifier"] = 3] = "Identifier";
    FieldGrouping[FieldGrouping["ArrayOfIdentifiers"] = 4] = "ArrayOfIdentifiers";
    FieldGrouping[FieldGrouping["ArrayOfObjects"] = 5] = "ArrayOfObjects";
    FieldGrouping[FieldGrouping["CreatedAt"] = 6] = "CreatedAt";
    FieldGrouping[FieldGrouping["UpdatedAt"] = 7] = "UpdatedAt";
})(FieldGrouping || (FieldGrouping = {}));
export function getOrderedFieldSchemas(fieldSchemas = []) {
    const groupedFields = {
        [FieldGrouping.Id]: _.compact([fieldSchemas.find(fs => fs.id === 'id')]),
        [FieldGrouping.Simple]: fieldSchemas.filter(fs => [
            ColumnTypes.string,
            ColumnTypes.integer,
            ColumnTypes.double,
            ColumnTypes.date,
            ColumnTypes.md,
        ].includes(fs.type) && (!fs.id || !['id', 'created_at', 'updated_at'].includes(fs.id))),
        [FieldGrouping.ArrayOfStrings]: fieldSchemas.filter(fs => fs.type === ColumnTypes['array of strings']),
        [FieldGrouping.Identifier]: fieldSchemas.filter(fs => fs.type === ColumnTypes['identifier object']),
        [FieldGrouping.ArrayOfIdentifiers]: fieldSchemas.filter(fs => fs.type === ColumnTypes['array of identifiers']),
        [FieldGrouping.ArrayOfObjects]: fieldSchemas.filter(fs => fs.type === ColumnTypes['array of objects']),
        [FieldGrouping.CreatedAt]: _.compact([fieldSchemas.find(fs => fs.id === 'created_at')]),
        [FieldGrouping.UpdatedAt]: _.compact([fieldSchemas.find(fs => fs.id === 'updated_at')]),
    };
    return _.flatMap(_.sortBy(_.entries(groupedFields), e => +e[0]), e => _.sortBy(e[1], fs => fs.label.toLowerCase()));
}
export default getOrderedFieldSchemas;
