import { defineStore } from 'pinia';
import ContactsApi from '@/api/ContactsApiV2';
const useSettings = defineStore('settings', {
    state: () => ({
        indexStatuses: [],
        contactsCount: 0,
        operation: false,
        systemBusy: false,
        indicesLoading: false,
    }),
    getters: {
        deprecatedIndices: (state) => {
            return state.indexStatuses.filter((index) => {
                return index.toBeRemoved && index.exists;
            });
        },
        actualIndices: (state) => {
            return state.indexStatuses.filter((index) => {
                return !index.toBeRemoved;
            });
        },
        readyIndices: (state) => {
            return state.indexStatuses.filter((index) => {
                return !index.exists && !index.toBeRemoved;
            });
        },
        appliedIndices: (state) => {
            return state.indexStatuses.filter((index) => {
                return index.exists && !index.toBeRemoved;
            });
        },
    },
    actions: {
        async getIndexStatuses() {
            this.indicesLoading = true;
            this.indexStatuses = await ContactsApi.administrationApi.getIndicesStatus();
            this.indicesLoading = false;
        },
        async getContactsCount() {
            this.contactsCount = await ContactsApi.administrationApi.getCountInTable('contact');
        },
        async getOperationInfo() {
            this.systemBusy = await ContactsApi.administrationApi.isSystemBusy();
        },
        async toggleIndex(index) {
            if (index.exists) {
                await ContactsApi.administrationApi.dropIndex(index);
            }
            else {
                await ContactsApi.administrationApi.addIndex(index);
            }
        },
        async addIndex(index) {
            await ContactsApi.administrationApi.addIndex(index);
        },
        async dropIndex(index) {
            await ContactsApi.administrationApi.dropIndex(index);
        },
    },
});
export default useSettings;
