import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, createElementVNode as _createElementVNode, withCtx as _withCtx, createVNode as _createVNode, createSlots as _createSlots } from "vue";
const _hoisted_1 = {
    class: "contacts-view flex flex-col gap-md overflow-x-hidden",
    "data-test": "contacts-view-sidebar"
};
const _hoisted_2 = { class: "actions flex flex-row justify-end" };
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_contacts_view_field = _resolveComponent("contacts-view-field");
    const _component_OrButtonV3 = _resolveComponent("OrButtonV3");
    const _component_OrConditionalSidebarModal = _resolveComponent("OrConditionalSidebarModal");
    return (_openBlock(), _createBlock(_component_OrConditionalSidebarModal, {
        ref: "rootRef",
        "data-test": "contact-info-modal",
        onClose: _cache[0] || (_cache[0] = ($event) => (_ctx.$emit('close')))
    }, _createSlots({
        default: _withCtx(() => [
            _createElementVNode("div", _hoisted_1, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.extendedFieldSchemas, (field) => {
                    return (_openBlock(), _createBlock(_component_contacts_view_field, {
                        key: field.id,
                        "field-schema": field,
                        data: _ctx.contactObject[field.id].value
                    }, null, 8, ["field-schema", "data"]));
                }), 128))
            ])
        ]),
        _: 2
    }, [
        (_ctx.isMobile)
            ? {
                name: "header",
                fn: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.$t('contacts.viewModal.title')), 1)
                ]),
                key: "0"
            }
            : undefined,
        (!_ctx.isReadonly)
            ? {
                name: "footer",
                fn: _withCtx(() => [
                    _createElementVNode("div", _hoisted_2, [
                        _createVNode(_component_OrButtonV3, {
                            "data-test": "edit-contact-info-button",
                            variant: "outlined",
                            onClick: _ctx.handleEdit
                        }, {
                            default: _withCtx(() => [
                                _createTextVNode(_toDisplayString(_ctx.$t('contacts.edit')), 1)
                            ]),
                            _: 1
                        }, 8, ["onClick"])
                    ])
                ]),
                key: "1"
            }
            : undefined
    ]), 1536));
}
